<ng-select [id] = "handler.htmlId"
           (change) = "this.handler.handleUserSubmit()"
           [(ngModel)]="value"
           [clearOnBackspace] = "false"
           [opAutofocus]="false"
           [clearable] = "true"
           [closeOnSelect] = "true"
           [disabled] = "inFlight"
           [hideSelected] = "false"
           [items]="availableValues"
           [multiple] = "false"
           [ngClass] = "'inline-edit--field'"
           [virtualScroll] = "false"
           [required] = "required"
           (open)="onOpen()"
           bindLabel="label"
           bindValue="key"
           [appendTo]="appendTo" />
