import { DisplayField } from 'core-app/shared/components/fields/display/display-field.module';

export class TaskComplexityDisplayFieldComponent extends DisplayField {
  public get valueString(): string {
    if (this.value == null) {
      return this.I18n.t('js.task_complexity.empty')
    }

    // TODO: Add human readable label
    return this.value.toLocaleString(
      this.I18n.locale,
      { useGrouping: true, maximumFractionDigits: 2 }
    );
  }
}
