import { Injector, NgModule } from '@angular/core';
import { OpenProjectPluginContext } from 'core-app/features/plugins/plugin-context';

import { FormsModule } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FocusModule } from 'core-app/shared/directives/focus/focus.module';

import { TaskComplexityEditFieldComponent } from './task-complexity-edit-field.component';
import { TaskComplexityDisplayFieldComponent } from './task-complexity-display-field.component';

@NgModule({
  imports: [
    FocusModule,
    FormsModule,
    NgSelectComponent,
  ],
  declarations: [
    TaskComplexityEditFieldComponent
  ],
  exports: [
    TaskComplexityEditFieldComponent
  ],
})

export class TaskComplexityFieldModule {
  constructor(injector: Injector) {
    window.OpenProject.getPluginContext().then((pluginContext: OpenProjectPluginContext) => {
      pluginContext.services.editField.addFieldType(TaskComplexityEditFieldComponent, 'float', ['TaskComplexity']);
      pluginContext.services.displayField.addFieldType(TaskComplexityDisplayFieldComponent, 'float', ['TaskComplexity']);
    });
  }
}
