<input
  type="checkbox"
  class="spot-checkbox--input-new"
  [attr.name]="name"
  [disabled]="disabled"
  [checked]="checked"
  (change)="onStateChange()"
  [tabindex]="tabindex"
  #input
/>
<span class="spot-checkbox--fake"></span>
