import { Component, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

import { EditFieldComponent } from 'core-app/shared/components/fields/edit/edit-field.component';

export interface TaskComplexitySelectType {
  key: string;
  label: string;
}

@Component({
  templateUrl: './task-complexity-edit-field.html'
})

export class TaskComplexityEditFieldComponent extends EditFieldComponent implements OnInit {
  @ViewChild(NgSelectComponent, { static: true }) public ngSelectComponent: NgSelectComponent;

  public availableValues: TaskComplexitySelectType[];
  public selectedValue: TaskComplexitySelectType;
  public appendTo: any = null;

  private valuesPath: string = 'js.task_complexity.values';

  public ngOnInit(): void {
    this.availableValues = [];
    for (const [key, value] of Object.entries(this.I18n.t(this.valuesPath))) {
      this.availableValues.push({
        key: key.toString(),
        label: `${key} (${value})`
      });
    }
    super.ngOnInit();
    this.appendTo = this.overflowingSelector;

    this.handler
      .$onUserActivate
      .pipe(
        this.untilDestroyed(),
      )
      .subscribe(() => {
        const that = this;
        window.setTimeout(() => {
          that.ngSelectComponent.open();
        }, 0);
      });
  }

  public onOpen() {
    jQuery('.__hidden_overflow_container').one('scroll', () => {
      this.ngSelectComponent.close();
    });
  }
}
