import './cell-builder-patch/cell-builder.patch';

import { Injector, NgModule } from '@angular/core';
import { TaskComplexityFieldModule } from './task-complexity-field/task-complexity-field.module';

@NgModule({
  imports: [
    TaskComplexityFieldModule
  ]
})

export class PluginModule {
  constructor(injector: Injector) { }
}
